require('../scss/app.scss');

require('@fancyapps/fancybox');
require('@fancyapps/fancybox/dist/jquery.fancybox.css');

require('../fonts/Geometria/css/Geometria.css');
require('../fonts/Heuristica/css/Heuristica.css');
require('../fonts/AGGaramondCyr/css/AGGaramondCyr.css');

require('./common/goal.js');
require('./common/js_validation.js');
require('./common/links.js');
require('./common/main.js');
require('./common/map.js');
require('./common/pagination.js');
require('./common/auto_animated');
require('./common/need-animation');
require('./common/zoom.js');

require('./common/ajax_detection.js');

require('./field/phone.js');
require('./field/select_choices.js');

require('./menu.js');

require('./common/sliders.js');