import {tns} from "tiny-slider/src/tiny-slider";
import vars from "../../scss/_settings/_settings.scss";
require('tiny-slider/src/tiny-slider.scss');

let sliders = document.querySelectorAll("[data-slider]");

function getThumbnailsOptions() {
  let axisVal = 'horizontal';
  let largePoint = parseInt(vars['large-breakpoint']);
  let mediumPoint = parseInt(vars['medium-breakpoint']);
  if (window.innerWidth >= mediumPoint && window.innerWidth < largePoint){
    axisVal = 'vertical';
  }

  let options = {
    slideBy: 1,
    items: 4,
    loop: false,
    axis: axisVal,
    freezable: false,
    responsive: {
      0: {
        gutter: 8,
      },
      768: {
        gutter: 24
      },
      1239: {
        gutter: 20
      }
    }
  };
  return options;
}



function initThumbnailsSlider() {
  let node = document.querySelector(".images-slider__thumbnails");
  let options = getThumbnailsOptions();

  let navSelector = node.dataset.parent;
  let nav = document.querySelector(navSelector);
  nav.querySelector('[data-slider-nav-prev]').onclick = function (button) {
    slider.goTo('prev');
  };
  nav.querySelector('[data-slider-nav-next]').onclick = function (button) {
    slider.goTo('next');
  };

  options = Object.assign(options, {
    container: node
  });
  let slider = {};

  setTimeout(function () {
    slider = tns(options);
    slider.events.on('newBreakpointEnd', function () {
      slider.destroy();
      initThumbnailsSlider();
    });
  }, 1000);
}



sliders.forEach((node) => {
  let options = {};
  if (node.dataset.mode == 'large-4') {
    options = {
      slideBy: 4,
      items: 4,
      loop: false,
      freezable: false,
      responsive: {
        0: {
          disable: true
        },
        1240: {
          disable: false,
          slideBy: 4,
          items: 4,
        },
      }
    }
  }
  if (node.dataset.mode == 'large-1') {
    options = {
      slideBy: 1,
      items: 1,
      autoHeight: 1,
    }
  }
  if (node.dataset.mode == 'images') {
    options = {
      slideBy: 1,
      items: 1,
      freezable: false,
      navContainer: ".images-slider__thumbnails",
      navAsThumbnails: true,
      loop: false,
      responsive: {
        0: {
        },
        768: {
        },
        1239: {
        }
      }
    }
  }


  let slider = {};
  let updateCounters = function () {};
  let hasNav = false;

  if (node.dataset.sliderNav) {
    hasNav = true;
    let navSelector = node.dataset.sliderNav;
    let nav = document.querySelector(navSelector);

    if (nav) {
      nav.querySelector('[data-slider-nav-prev]').onclick = function (button) {
        slider.goTo('prev');
      };
      nav.querySelector('[data-slider-nav-next]').onclick = function (button) {
        slider.goTo('next');
      };
    }
    updateCounters = function () {
      let info = slider.getInfo();
      if (info) {
        let navCurrentIndex = slider.getInfo().navCurrentIndex;
        let cIndex = navCurrentIndex + 1;
        let aIndexes = info.pages;
        if (nav) {
          nav.querySelector('[data-slider-nav-current]').innerHTML = cIndex;
          nav.querySelector('[data-slider-nav-total]').innerHTML = aIndexes;
        }
      }
    };
  }

  options = Object.assign(options, {
    container: node
  });

  setTimeout(function () {
    slider = tns(options);

    if (node.dataset.mode == 'images') {

      slider.events.on('newBreakpointEnd', function () {
        setTimeout(function () {
          slider.rebuild();
        }, 2000)
      });
    }

    if (hasNav) {
      slider.events.on('transitionEnd', function () {
        updateCounters();
      });
      slider.events.on('newBreakpointEnd', function () {
        updateCounters();
      });
      updateCounters();
    }
  }, 1000);

});
if (document.querySelector(".images-slider__thumbnails")){
  initThumbnailsSlider();
}

