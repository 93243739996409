$(() => {
  $(document).ready(function () {
    setTimeout(function () {
      autoAnimation();
      scrollAnimated();
    }, 1000)
  });

  $(window).on('scroll', function () {
    scrollAnimated()
  });

  function autoAnimation() {
    $('.auto-animated').each(function () {
      $(this).removeClass('auto-animated');
    });
  }

  function scrollAnimated() {
    let $scrollers = $('.scroll-animated');
    let currentPosition = $(window).scrollTop() + $(window).height();
    $scrollers.each(function () {
      let kf = parseFloat($(this).data('scroll-kf')) || 0;
      console.log(kf);
      if ($(this).offset().top  + ($(this).height() * kf) < currentPosition){
        $(this).removeClass('scroll-animated');
      }
    });
  }
});