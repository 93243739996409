function initMapGoogle()
{
  /*
  let infowindow = new google.maps.InfoWindow({
    content: '<div class="contacts-lines popup">' + $('.contacts-lines').html() + '</div>'
  });
  */

  let customMapType = new google.maps.StyledMapType([
      {
          "featureType": "administrative",
          "elementType": "all",
          "stylers": [
              {
                  "visibility": "simplified"
              }
          ]
      },
      {
          "featureType": "landscape",
          "elementType": "geometry",
          "stylers": [
              {
                  "visibility": "simplified"
              },
              {
                  "color": "#fcfcfc"
              }
          ]
      },
      {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
              {
                  "visibility": "simplified"
              },
              {
                  "color": "#fcfcfc"
              }
          ]
      },
      {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
              {
                  "visibility": "simplified"
              },
              {
                  "color": "#dddddd"
              }
          ]
      },
      {
          "featureType": "road.arterial",
          "elementType": "geometry",
          "stylers": [
              {
                  "visibility": "simplified"
              },
              {
                  "color": "#dddddd"
              }
          ]
      },
      {
          "featureType": "road.local",
          "elementType": "geometry",
          "stylers": [
              {
                  "visibility": "simplified"
              },
              {
                  "color": "#eeeeee"
              }
          ]
      },
      {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
              {
                  "visibility": "simplified"
              },
              {
                  "color": "#dddddd"
              }
          ]
      }
  ], {
    name: 'Custom Style'
  });
  let customMapTypeId = 'custom_style';

  document.querySelectorAll('[data-map]').forEach(function initMapElement(mapElement) {
    let map = new google.maps.Map(mapElement, {
      zoom: 15,
      center: {
        lat: parseFloat(mapElement.dataset.lat),
        lng: parseFloat(mapElement.dataset.lng)
      },
      mapTypeControlOptions: {
        mapTypeIds: [google.maps.MapTypeId.ROADMAP, customMapTypeId]
      },
      panControl: false,
      zoomControl: true,
      scaleControl: false,
      streetViewControl: false,
      scrollwheel: false,
      mapTypeControl: false
    });

    map.mapTypes.set(customMapTypeId, customMapType);
    map.setMapTypeId(customMapTypeId);

    let marker = new google.maps.Marker({
      position: {
        lat: parseFloat(mapElement.dataset.lat),
        lng: parseFloat(mapElement.dataset.lng)
      },
      icon: mapElement.dataset.mark,
      map: map
    });
  });
  /*
  marker.addListener('click', function() {
    infowindow.open(map, marker);
  });
  */
}

function initMapYandex() {
  document.querySelectorAll('[data-map]').forEach(function initMapElement(mapElement) {
    let myMap = new ymaps.Map(mapElement, {
      center: [parseFloat(mapElement.dataset.lat), parseFloat(mapElement.dataset.lng)],
      zoom: mapElement.dataset.zoom,
      controls: ['zoomControl', 'fullscreenControl']
    }, {
      searchControlProvider: 'yandex#search'
    });

    let myPlacemark = new ymaps.Placemark(myMap.getCenter(), {}, {
      // Опции.
      // Необходимо указать данный тип макета.
      iconLayout: 'default#image',
      // Своё изображение иконки метки.
      iconImageHref: mapElement.dataset.mark,
      // Размеры метки.
      iconImageSize: [43, 57],
      // Смещение левого верхнего угла иконки относительно
      // её "ножки" (точки привязки).
      iconImageOffset: [-21, -57]
    });

    myMap.behaviors.disable('scrollZoom');
    if (window.innerWidth <= 1024) {
      myMap.behaviors.disable('drag');
    }
    myMap.geoObjects.add(myPlacemark);
  });
}
// google.maps.event.addDomListener(window, 'load', initMap);

window.addEventListener('load', () => {
  if (window.google) {
    initMapGoogle();
  }
  if (window.ymaps) {
    ymaps.ready(initMapYandex);
  }
});
