$(document).on('click', '[data-endless-action]', function (e) {
  e.preventDefault();
  let $button = $(this);
  let key = $button.closest('[data-pagination-nav]').data('pagination-nav');
  if (!$button.data('loading')) {
    $button.data('loading', true);
    $.ajax($button.attr('href')).then(function (page) {
      let $page = $('<div/>').append(page);
      let $newData = $page.find('[data-pagination-data="'+key+'"]');
      let $currentData = $('[data-pagination-data="'+key+'"]');
      $newData.children().each(function appendItem(item) {
        $currentData.append(this);
      });
      $button.data('loading', false);
      $('[data-pagination-nav="'+key+'"]').replaceWith($page.find('[data-pagination-nav="'+key+'"]'))
    });
  }
  return false;
});