import Eve from './event.js'
import Magnifier from './magnifier.js'

if (innerWidth > 1239) {
    var evt = new Eve(),
        m = new Magnifier(evt);
    m.attach({
        thumb: '.images-slider__img',
        largeWrapper: 'preview',
        zoom: 2,
        zoomable: true
    });
}