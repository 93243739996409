$(() => {
  $('.header__hamburger-link').on('click', function (e) {
    e.preventDefault();
    $(this).toggleClass('header__hamburger-link_open');
    $('.header__mobile-menu').toggleClass('header__mobile-menu_open');
    $('body').toggleClass('fixed');
  });

  $('.mobile-menu__link_parent').on('click', function (e) {
    e.preventDefault();
    let $child = $($($(this).closest('.mobile-menu__item')).find('.mobile-menu__child-container'));
    $child.toggleClass('mobile-menu__child-container_active');
  })
});