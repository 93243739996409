let elements = [];
let lastCollect = new Date();

function collectElements() {
  elements = document.querySelectorAll('[data-need-animation]:not(.animate)');
  lastCollect = new Date();
}

function safeCollect() {
  let now = new Date();
  if ((now - lastCollect) > 1000) {
    collectElements();
  }
}

function check() {
  safeCollect();
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  let windowHeight = window.innerHeight;
  let scrolled = scrollTop + windowHeight;

  elements.forEach((element) => {
    let bounds = element.getBoundingClientRect();
    let offsetTop = bounds.top + scrollTop;

    if (element.className.indexOf('animate') === -1 && offsetTop && !element.dataset.transit) {
      let delay = element.dataset.delay || 0;
      let ratio = element.dataset.ratio || 2;
      let fully = element.dataset.fully || 0;
      let animate = false;

      if (fully) {
        if ((bounds.height + offsetTop) < scrolled) {
          animate = true;
        }
      } else if ((bounds.top) < (windowHeight/ratio)){
        animate = true;
      }

      if (animate) {
        element.dataset.transit = 'on';
        setTimeout(() => {
          element.classList.add('animate');
          element.classList.remove('need-animation');
        }, delay)
      }
    }
  });
}

function bind() {
  window.addEventListener('scroll', check);
  check();
}

document.addEventListener('DOMContentLoaded', () => {
  collectElements();
  bind();
});

$( document ).ajaxComplete(function() {
  collectElements();
  bind();
});